import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Info from "../components/info"
import Symentec from "../components/symentec"
import RR from "../components/rr"

const Hero = styled.section`
  font-family: "extended";
  text-transform: uppercase;
  display: flex;
  padding: 40vw 6vw 10vw;
  text-align: left;
  font-weight: 800;
  font-size: 8vw;
  line-height: 11vw;

  @media (min-width: 1200px) {
    font-size: 4vw;
    padding: 15vw 25vw 5vw;
    line-height: 6vw;
  }
`

const Body = styled.section`
  font-family: "Canela", sans-serif;
  color: white;
  font-size: 5vw;
  line-height: 10vw;
  padding: 0 6vw;
  text-align: justify;
  text-justify: inter-word;

  @media (min-width: 1200px) {
    font-size: 1.2vw;
    line-height: 2vw;
    padding: 0 25vw;
  }

  a {
    text-decoration: underline;
  }
`
const Images = styled.div`
  padding: 10vw 6vw;

  @media (min-width: 1200px) {
    padding: 4vw 25vw;
  }

  h2 {
    font-family: "extended";
    font-size: 3vw;
    text-transform: uppercase;
    padding: 0 0 4vw;
    font-weight: 800;

    @media (min-width: 1200px) {
      font-size: 1vw;
      text-transform: uppercase;
      padding: 0 0 4vw;
      font-weight: 800;
    }
  }
`

const Heading = styled.h2`
  font-family: "extended";
  font-size: 3vw;
  text-transform: uppercase;
  padding: 0 0 4vw;
  font-weight: 800;

  @media (min-width: 1200px) {
    font-size: 1vw;
    text-transform: uppercase;
    padding: 0 0 4vw;
    font-weight: 800;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const ImageBox = styled.div`
  padding: 2vw 20vw;
  @media (min-width: 1200px) {
    padding: 0;
    width: 20vw;
  }
`
const IndexPage = () => (
  <Layout>
    <SEO title="Welcome" />
    <Hero>
      One goal
      <br />
      one vision
    </Hero>
    <Body>
      Must consultancy is gespecialiseerd in interim sales opdrachten in
      infrastructuur software en met name SAAS applicaties. Resultaat gericht en
      meer dan 10 jaar ervaring in SAAS oplossingen.
      <br />
      <br />
      Wij zijn gespecialiseerd in start-up software bedrijven die nog zoekend
      zijn naar haar positie in de markt). Wij helpen deze de stadia te laten
      doorlopen van start-up via de rollercoaster- en de puber fasefase naar de
      scale-up fase (van 0 naar >100 personeelsleden).
      {/* <br /> <br /> Veel ervaring binnen de software industrie met omzet groei
      van 0 naar multi-miljoenen. */}
      <br /> <br />
      {/* <br /> */}
      {/* <h2>U kunt ons benaderen voor</h2>
      <ul>
        <li>Interim Sales manager</li>
        <li>Interim Account management</li>
        <li>Interim Commercial director</li>
        <li>Interim Sales manager</li>
      </ul>
      <br /> */}
      Als interim Sales Manager geven wij veelal leiding aan een team van
      verkopers of bouwen dit team op en houden we ons daarnaast bezig met de
      verkoop van producten en diensten. Een nauwe samenwerking met de
      marketingafdeling is hierbij cruciaal. In overleg met het management
      stellen wij een strategisch verkoopplan op en coördineren wij de
      marktonderzoeken. Doelstellingen worden gerealiseerd, teams presteren
      beter of worden vernieuwd/aangepast en de strategie wordt voor iedereen
      duidelijk.
      <br /> <br />
    </Body>
    <Images>
      <Heading>Referenties</Heading>
      <Box>
        <ImageBox>
          <Info />
        </ImageBox>
        <ImageBox>
          <Symentec />
        </ImageBox>
        <ImageBox>
          <RR />
        </ImageBox>
      </Box>
    </Images>
    <Body>
      <Heading>Contact</Heading>
      Heb je vragen? Of wil je meer informatie? Stuur ons een{" "}
      <a href="mailto:hello@mustconsultancy.com">mail</a>
      <br />
    </Body>
  </Layout>
)

export default IndexPage
